import { useEffect } from "react";

export const useCustomCursor = () => {
  useEffect(() => {
    const mouseEl = document.getElementById("mouse-dragger");
    if (window.innerWidth < 960 || !mouseEl) return;

    const hoverElements = document.querySelectorAll("[data-cursor='play'], [data-cursor='drag']");

    let currentX = window.innerWidth / 2,
      currentY = window.innerHeight / 2,
      targetX = window.innerWidth / 2,
      targetY = window.innerHeight / 2;

    const getInitialPos = (t: any) => {
      window.removeEventListener("mousemove", getInitialPos);

      targetX = t.clientX;
      targetY = t.clientY;
      currentX = targetX;
      currentY = targetY;

      window.requestAnimationFrame(setPos);
      window.addEventListener("mousemove", getPos, { passive: true });
    }

    const getPos = (t: any) => {
      targetX = t.clientX;
      targetY = t.clientY;
    }
    const setPos = () => {
      currentX += .5 * (targetX - currentX);
      currentY += .5 * (targetY - currentY);

      // mouseEl.style.transform = `translate(${currentX}px, ${currentY}px)`
      mouseEl.style.left = `${currentX}px`
      mouseEl.style.top = `${currentY}px`

      window.requestAnimationFrame(setPos);
    }

    const changeCursor = (e: any) => {
      document.documentElement.classList.add("no-cursor")
      if (e.target && e.target.getAttribute('data-cursor')) {
        mouseEl?.classList.add(e.target.getAttribute('data-cursor'));

        if (e.target.querySelector('video')) {
          revertCursor(e);
        }
      }
    }
    const revertCursor = (e: any) => {
      document.documentElement.classList.remove("no-cursor")
      if (e.target && e.target.getAttribute('data-cursor')) {
        mouseEl?.classList.remove(e.target.getAttribute('data-cursor'))
      }
    }

    window.addEventListener("mousemove", getInitialPos, { passive: true });

    if (hoverElements) {
      hoverElements.forEach((el) => {
        el.addEventListener('mouseenter', changeCursor, {passive: true});
        el.addEventListener('mouseleave', revertCursor, {passive: true});
      })
    }
    return () => {
      document.documentElement.classList.remove("no-cursor")
      window.removeEventListener("mousemove", getInitialPos);
      window.removeEventListener("mousemove", getPos);

      if (hoverElements) {
        hoverElements.forEach((el) => {
          el.removeEventListener('mouseenter', changeCursor);
          el.removeEventListener('mouseleave', revertCursor);
        })
      }
    }
  }, [])
}
