//@ts-nocheck
"use client";

import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { debounce, useMediaMatch } from 'oddsgate-ds';

import ScrollTrigger from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import onAppearAnimations from "@/lib/helpers/animations";
import { useCustomCursor } from "@/lib/helpers/customCursor";
import { useGlobalContext } from "@/lib/globalContext";

const NavigationEvents: any = ({
  translatedURI,
  acf,
}: {
  translatedURI: Record<string, string>;
  acf?: Record<string, string>;
}) => {
  const { lenis, translatedUri, setTranslatedUri, pageTitle, setPageTitle } = useGlobalContext();

  useEffect(() => {
    if (JSON.stringify(translatedUri) !== JSON.stringify(translatedURI)) {
      if (setTranslatedUri && translatedURI) setTranslatedUri(translatedURI);
    }
  }, [translatedUri, translatedURI, setTranslatedUri]);

  useEffect(() => {
    if (acf?.pageTitle) {
      if (setPageTitle) setPageTitle(acf?.pageTitle);
    } else if (pageTitle) {
      setPageTitle(undefined);
    }
  }, [acf?.pageTitle, setPageTitle]);


  //force resize to make sure every animation is in right place;
  const resetScrollTriggerPos = debounce(() => {
    const triggers = ScrollTrigger.getAll();
    triggers.forEach((trigger, i) => {
      trigger.refresh();
    })

    ScrollTrigger.refresh()
  }, 50)

  const isMobile = useMediaMatch();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      lenis?.scrollTo(0, { immediate: true, force: true });

      onAppearAnimations();

      const animationSelector = gsap.utils.selector("body");
      if (acf) {
        document.documentElement.classList.remove(`style-light`, `style-dark`);
        document.documentElement.classList.remove(`header-absolute`);

        if (acf.page_style) {
          document.documentElement.classList.add(`style-${acf.page_style}`);
        }

        if (acf.header_absolute) {
          document.documentElement.classList.add(`header-absolute`);
        }
        // gsap.timeline({
        //   scrollTrigger: {
        //     trigger: header,
        //     start: "top",
        //     end: () => `+=${document.documentElement.offsetHeight}`,
        //     invalidateOnRefresh: true,
        //     onUpdate: (self) => {
        //       self.direction === -1 ? header?.classList.remove('header-hidden') : header?.classList.add('header-hidden')
        //     }
        //   },
        // });
      }

      const header = document.getElementById("masthead");
      gsap.timeline({
        scrollTrigger: {
          trigger: "body",
          start: () => "top top",
          end: () => `bottom bottom`,
          invalidateOnRefresh: true,
          onToggle: (self) => {
            self.progress > 0 ? header?.classList.add('scrolled') : header?.classList.remove('scrolled');
          }
        },
      });


      //custom animations
      const homeIntro = animationSelector(".home-intro")[0];
      if (homeIntro) {
        let box = homeIntro.querySelector(".svg-group"),
        dot = homeIntro.querySelector(".left-svg"),
        dot2 = homeIntro.querySelector(".right-svg");
                
        if(box){
          const boxSet = gsap.quickSetter(box, "css");
          boxSet({
            y: "-5dvw",
          })
        }
        if(dot){
          const dotSet = gsap.quickSetter(dot, "css");
          dotSet({
            left: "50%",
            x: "-50%",
            scale: 0.6,
          })
        }
        if(dot2){
          const dot2Set = gsap.quickSetter(dot2, "css");
          dot2Set({
            right: "50%",
            x: "50%",
            scale: 0.6,
          })
        }

        let tl1 = gsap.timeline({
          scrollTrigger: {
            trigger: homeIntro,
            pin: box,
            start: "top top",
            end: "bottom bottom",
            scrub: 1,
            invalidateOnRefresh: true
          },
          defaults: { ease: "none" }
        });

        tl1
          .to(
            dot,
            {
              scale: 1,
              color: "#440D3F",
              // top: "13%",
              left: "15%"
            }, 0
          )
          .to(
            dot2,
            {
              scale: 1,
              color: "#440D3F",
              // top: "13%",
              right: "15%"
            }, 0
          );
      }

      const animatedCircle = animationSelector(".animated-circle")[0];
      if (animatedCircle) {
        gsap.to(animatedCircle, {
          x: animatedCircle?.parentElement ? (animatedCircle.parentElement.clientWidth + animatedCircle.parentElement.nextElementSibling.clientWidth) - animatedCircle.clientWidth - 16 : 0,
          ease: "none",
          scrollTrigger: {
            trigger: animatedCircle,
            start: "center 80%",
            end: "center 20%",
            scrub: 1,
            invalidateOnRefresh: true
          }
        });
      }


      const horzintalContainer = animationSelector(".horizontal-section")[0];
      if (horzintalContainer) {
        const increment = isMobile ? 18 : 3.5;
        const duration = horzintalContainer.offsetWidth * increment;

        // gsap.to(animationSelector(".horizontal-title")[0], {
        //   x: () => (horzintalContainer.scrollWidth - document.documentElement.clientWidth) + "px",
        //   ease: "none",
        //   scrollTrigger: {
        //     trigger: horzintalContainer,
        //     start: 'top top',
        //     end: () => "+=" + duration,
        //     scrub: 1,
        //     invalidateOnRefresh: true
        //   }
        // });

        const tl = gsap.timeline({
          paused: true,
          scrollTrigger: {
            trigger: horzintalContainer,
            pin: true,
            pinSpacing: true,
            scrub: 1,
            end: () => "+=" + duration,
            invalidateOnRefresh: true
          },
          defaults: { ease: "none" }
        })
        tl.to(horzintalContainer, {
          x: () => -(horzintalContainer.scrollWidth - document.documentElement.clientWidth) + "px",
        });
      }

      // let eyeElement = document.querySelector(".svg-eye");
      // if (eyeElement && !isMobile) {
      //   const pupil = eyeElement.querySelector('circle');

      //   const maxTrans = 18;
      //   let maxXDist: number, maxYDist: number;
      //   let centerX: number, centerY: number;

      //   const resize = () => {
      //     maxXDist = innerWidth / 2;
      //     maxYDist = innerHeight / 2;

      //     if (pupil) {
      //       const eyeArea = pupil.getBoundingClientRect();
      //       const R = eyeArea.width / 2;
      //       centerX = eyeArea.left + R;
      //       centerY = eyeArea.top + R;
      //     }
      //   }

      //   const updateTrans = (e: any) => {
      //     // Calculate the distance from the mouse position to the center.
      //     const x = e.clientX - centerX;
      //     const y = e.clientY - centerY;
      //     // const dist = Math.sqrt(Math.pow(x, 2) + Math.pow(x, 2)); // optionally use the total distance as a factor or restriction

      //     // Put that number over the max distance from 2)
      //     const xPercent = x / maxXDist;
      //     const yPercent = y / maxYDist;

      //     // Multiply that product by the max value from 1 and apply it to your element.
      //     const scaledXPercent = xPercent * maxTrans;
      //     const scaledYPercent = yPercent * maxTrans;

      //     gsap.to(pupil, { xPercent: scaledXPercent, yPercent: scaledYPercent, duration: 0.2, overwrite: 'auto' });
      //   }

      //   ScrollTrigger.create({
      //     trigger: '.svg-eye',
      //     start: 'top bottom',
      //     end: 'bottom top',
      //     onEnter: () => {
      //       console.log("onEnter")
      //       resize();
      //       document.addEventListener('mousemove', updateTrans);
      //     },
      //     onEnterBack: () => {
      //       console.log("onEnterBack")
      //       resize();
      //       document.addEventListener('mousemove', updateTrans);
      //     },
      //     onLeaveBack: () => {
      //       console.log("onLeaveBack")
      //       resize();
      //       document.removeEventListener('mousemove', updateTrans);
      //     },
      //     onLeave: () => {
      //       console.log("onLeave")
      //       resize();
      //       document.removeEventListener('mousemove', updateTrans);
      //     }
      //   });

      // }

      // const contentElements = gsap.utils.toArray(".parallax-cards > div > div:not(:first-child)");
      // if (contentElements && contentElements.length > 0) {
      //   const container = animationSelector('.parallax-cards > div')[0];

      //   const tl = gsap.timeline({
      //     scrollTrigger: {
      //       trigger: animationSelector(".parallax-cards-wrappper")[0],
      //       pin: true,
      //       scrub: 1,
      //       start: "top top",
      //       end: () => "+=" + (container.scrollWidth * contentElements.length) * 3,
      //       invalidateOnRefresh: true
      //     },
      //     paused: true,
      //     defaults: { ease: "none" }
      //   })
      //   contentElements.forEach((el, i) => {
      //     gsap.quickSetter(el, {
      //       xPercent: ((i + 1) * 100),
      //     })
      //     tl.to(el, {
      //       xPercent: 0,
      //     }, ">")
      //   });
      // }

      const fixedGroups = gsap.utils.toArray(".fixed-blocks > div");
      if (fixedGroups && fixedGroups.length > 0) {
        fixedGroups.forEach((el, position) => {

          gsap.timeline({
            paused: true,
            scrollTrigger: {
              trigger: el,
              start: 'center center',
              end: '+=100%',
              scrub: 1,
              invalidateOnRefresh: true
            },
            defaults: { ease: "none" }
          })
            .to(el, {
              ease: 'none',
              scale: 0.6,
              opacity: 0,
              //yPercent: isLast ? 125 : 0
            }, 0);
        });
      }

      const splittingElements = gsap.utils.toArray(".splitted");
      if (splittingElements && splittingElements.length > 0) {
        import('splitting')
          .then((module) => {
            module.default({ target: '.splitted' })
          })
          .catch((err) => {
            console.error(err);
          });
      }

      const careersImgs = gsap.utils.toArray(".careers-effectimg");
      if (careersImgs && careersImgs.length > 0) {
        careersImgs.forEach((el, position) => {

          gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: 'top bottom',
              end: '+=40%',
              scrub: 1,
              invalidateOnRefresh: true
            },
            defaults: { ease: "none" }
          })
            .to(el, {
              scale: 1,
              opacity: 1,
            }, 0)
            .to(el.querySelector('img'), {
              rotate: 0,
              // yPercent: isLast ? 125 : 0
            }, 0);
        });
      }

      const spacedBlocks = gsap.utils.toArray(".spaced-scroll > div > div");
      if (spacedBlocks && spacedBlocks.length > 0) {
        if (!isMobile) {
          spacedBlocks.forEach((el, position) => {
            gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: 'top bottom',
                end: '+=40%',
                scrub: 1,
                invalidateOnRefresh: true
              },
              defaults: { ease: "none" }
            })
              .to(el, {
                x: 0,
                scale: 1,
              }, 0)
          });
        } else {
          gsap.set([...spacedBlocks], { clearProps: true })
        }
      }

      setTimeout(() => {
        resetScrollTriggerPos();
      }, 250);
    });

    return () => {
      ctx.revert();
    } // <- cleanup!
  }, [acf, lenis, isMobile]);


  useLayoutEffect(() => {
    //anchors by slug
    if (location) {
      const hash = location.pathname.slice(0, -1).split("/").pop();
      if (hash && document.getElementById(hash)) {
        (document.getElementById(hash) as HTMLDivElement).scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, []);

  useCustomCursor();

  return null;
};
export default NavigationEvents;
